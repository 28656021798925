#
# Select city_id by zip code
#

$.extend $.y, {

  selectCityByZip: 

    init: (node) ->
      @_zip = $(node)
      #@_zipCodeMask()
      if @_s('city-block').find('select > option').length > 1
        @_s('city-block').show() 
      @_s('country').on 'change', => @_countryChanged()
      @_zip.on 'keyup', => @_checkZip()

    _s: (f) -> $("[data-city-by-zip=\"#{f}\"]")

    _zipChanged: ->
      if @_zip.val()
        @_zip.blur()
        $.get @_zip.data('url'), { country_id: @_s('country').val(), zip_code: @_zip.val() }, (data) =>
          if data.length is 0
            $.y.flashMessage.create I18n.t('js.city.unknown_zip'), 'alert'
            @_reset()
          else
            @_s('city-block').find('.error-message').hide()
            s = @_s('city-block').find('select').empty()
            s.parents('.custom-select').toggleClass('one-element', data.length is 1)
            s.append $('<option />').val(null).text(I18n.t 'common.choose') if data.length > 1              
            s.append($('<option />').val(d.id).text(d.name)) for d in data  
            s.trigger 'change'
            @_s('city-block').show()
      else
        @_reset()

    _countryChanged: ->
      @_reset()
      #@_zipCodeMask()

    _checkZip: ->
      if @_zip.val() == ''
        @_reset()
      else
        mask = new RegExp(@_s('country').find(':selected').data('zip-code-mask'))
        if @_zip.val().match(mask)
          @_zipChanged()
        else if @_zip.val().length is 5
          $.y.flashMessage.create I18n.t('js.city.unknown_zip'), 'alert'
          @_reset()  

    _reset: ->
      @_zip.val('').blur()
      @_s('city-block').hide().find('select').empty().append $('<option />').val(null)

    _zipCodeMask: -> 
      # @_zip.inputmask 'Regex', { 
      #   regex: @_s('country').find(':selected').data('zip-code-mask'),
      #   placeholder: ' ', 
      #   oncomplete: => @_zipChanged(), 
      #   oncleared: => @_reset() 
      # }

}

$.fn.selectCityByZip = ->
  $.y.selectCityByZip.init $(@).first() if $(@).length > 0