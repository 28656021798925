#
# Yoos Modal box
#

import { actionCallbackXhr } from 'commons/main'

export default class Modal
  constructor: (type, options) ->
    @id    = "modal_#{Date.now()}"
    @_node = switch type
      when 'alert'   then @_alert options['title'], options['message'], options['callback']
      when 'confirm' then @_confirm options['title'], options['message'], options['callback']
      when 'content'
        $(document).on "ajax:success.#{@id}", "##{@id}", (e) =>
          return if e.target.dataset.modal
          [data, status, xhr] = e.detail
          try
            if $(xhr.responseText).findIncludeSelf('[data-modal="body"]').length
              elmt = @_node.find '[data-modal="content"]'
              elmt.html(xhr.responseText).trigger 'y:domChanged'
              actionCallbackXhr xhr
              @_resetPositionCache()
              @_position()
            else
              @destroy()
        $(document).on "ajax:error.#{@id}", "##{@id}", (e) =>
          [status, error, xhr] = e.detail
          try
            if $(xhr.responseText).findIncludeSelf('[data-modal="body"]').length
              elmt = @_node.find '[data-modal="content"]'
              elmt.html(xhr.responseText).trigger 'y:domChanged'
              actionCallbackXhr xhr
              @_resetPositionCache()
              @_position()
            else
              @destroy()

        @_create options['content'], options['style']

    # Observe resize on modal <div> elements
    if window.ResizeObserver
      @_modalResizeObserver = new ResizeObserver (entries) =>
        # We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
        window.requestAnimationFrame =>
          if !Array.isArray(entries) || !entries.length
            return
          @_position()

      @_modalResizeObserver.observe @_node.get(0).querySelector('div')

    $(document).on "page:before-unload.#{@id}", => @destroy()
    @_node.on "y:close.#{@id}", => @destroy()
    @_node.on 'click', '[data-modal="close"]', (e) =>
      e.preventDefault()
      @destroy()
    @_position()

  show: -> @_node.addClass 'modal-show'

  destroy: ->
    $([window, document]).off ".#{@id}"
    @_node.remove()

  # Static

  @start: ->
    # Remote modals
    $(document).on 'ajax:success', '[data-modal="true"]', (e) =>
      [data, status, xhr] = e.detail
      data    = xhr.responseText
      mt      = $(e.target).data 'modal-style'
      options = {content: data}
      options = $.extend(options, {style: mt}) if mt
      (new @ 'content', options).show()
      actionCallbackXhr xhr

    # Override Rails confirms
    $(document).on 'confirm', (e) ->
      element = $(e.target)
      return true unless element.attr('data-confirm')
      Modal.confirm element.data('title'), element.data('confirm'), ->
        element.removeAttr('data-confirm')
        element.get(0).click()
      false

  @alert: (title, message, onCloseCallback) ->
    (new @ 'alert', {title: title, message: message, callback: onCloseCallback}).show()

  @confirm: (title, message, onConfirmCallback) ->
    (new @ 'confirm', {title: title, message: message, callback: onConfirmCallback}).show()

  # Private

  _create: (content, style='') ->
    $ """
      <div id="#{@id}" class="modal #{style}">
        <div class="modal-dialog">
          <div class="modal-dialog-inner">
            <div class="modal-header"><button class="modal-close btn" data-modal="close"></button></div>
            <div data-modal="content">#{content}</div>
          </div>
        </div>
        <div class="modal-overlay" data-modal="close"></div>
      </div>
      """
      .appendTo('body').trigger 'y:domChanged'

  _content: (body, footer) ->
    """
    <div class="modal-body" data-modal="body">
      <div class="modal-scrollbox">
        #{body}
      </div>
    </div>
    <div class="modal-footer" data-modal="footer">
      #{footer}
    </div>
    """

  _alert: (title, message, callback) ->
    body   = "<h1><div class=\"modal__title\">#{title}</div></h1><p>#{message}</p>"
    footer = """
             <a href="#" class="btn btn-lg" data-modal="close">#{I18n.t 'common.ok'}</a>
             """
    node = @_create @_content(body, footer)
    node.find('[data-modal="close"]').one 'click', (e) => callback() if callback
    node

  _confirm: (title, message, callback) ->
    body   = "<h1 class=\"modal__title\">#{title}</h1><p>#{message}</p>"
    footer = """
             <a href="#" class="btn btn-lg btn-color-b btn-modal-confirm" data-modal="close">#{I18n.t 'common.no'}</a>
             <a href="#" class="btn btn-lg btn-modal-confirm" data-modal="confirm">#{I18n.t 'common.yes'}</a>
             """
    node = @_create @_content(body, footer)
    node.find('[data-modal="confirm"]').one 'click', (e) =>
      e.preventDefault()
      @destroy()
      callback()
    node

  _position: ->
    pad   = 20
    @_m   ||= @_node.children(':first')
    @_ms  ||= @_m.find('.modal-scrollbox')
    @_ms.outerHeight('')
    @_mfh ||= @_m.outerHeight() - @_ms.outerHeight()
    wh    = $(window).outerHeight()

    if wh - pad * 2 < @_mfh + @_ms.prop('scrollHeight')
      @_m.css {top: pad, 'margin-top': 0}
      @_ms.outerHeight(wh - @_mfh - pad * 2, true)
      @_node.addClass 'with-scroll'
    else
      @_node.removeClass 'with-scroll'
      @_m.css {top:'50%', 'margin-top': -@_m.outerHeight()/2}

    @_node

  _resetPositionCache: ->
    @_ms = @_mfh = null
