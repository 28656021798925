#
# Admin Controllers / actions specific javascript
#

import Rails from '@rails/ujs'
import Hammer from 'hammerjs'
import Modal from './modal'

$.extend true, $.y, {

  controllers:

    account:

      users:

        create_act: ->
          $('[data-register-back]').click (e) ->
            e.preventDefault()
            Rails.fire $(e.target).closest('form').prop('action', $(e.target).prop('href')).get(0), 'submit'

          @edit_act()

        edit_act: ->
          #$('.date').inputmask I18n.t('js.inputmask.date'), { placeholder: '_', yearrange: { minyear: 1900, maxyear: new Date().getFullYear() } } unless $('body').hasClass('mobile') or $('body').hasClass('tablet')
          
          if $('.form-control.date').length
            $('.form-control.date').datetimepicker({
              widgetParent: $('.user-firstname-born'), 
              widgetPositioning: {horizontal: 'left'},
              useCurrent: false,
              format: I18n.t('js.datepicker.date'), 
              locale: I18n.locale, 
              minDate: new Date(new Date().getFullYear()-100, new Date().getMonth(), 1)
            }) 

          $('#user_zip_code[type=text]').selectCityByZip()
          $('[data-password-field] button').click (e) ->
            elmt = $(e.currentTarget).prev()
            elmt.attr('type', if elmt.attr('type') is 'text' then 'password' else 'text')

        update_act: -> @edit_act()

        after_create_welcome_act: -> fbq('track', 'CompleteRegistration') if window.fbq?

        after_create_hobbies_act: -> $.y.controllers.account.hobbies.index_act()

        email_settings_act: ->
          $('[data-radio-ctrl]').each (i, elmnt) ->
            selector = "input[name*=#{$(elmnt).data('radio-ctrl')}]"
            $(elmnt).prop('checked', $("#{selector}:checked").val() > 0).trigger 'y:domChanged'
            $("#{selector}[type=radio]").click (e) -> $(elmnt).prop('checked', true).trigger('y:domChanged')

          $('[data-radio-ctrl]').click (e) ->
            selector = "input[name*=#{$(e.target).data('radio-ctrl')}]"
            if $(e.target).is(':checked')
               $("#{selector}[value=1]").prop 'checked', true
            else
              $(selector).prop 'checked', false
            $(e.target).parents('form').trigger('y:domChanged').submit()

      hobbies:

        index_act: ->
          # Toggle favotrite hobbies / add, remove hobby
          toggleFavorite = ($label, type) ->
              label   = $label
              cb      = $label.find('input')
              counter = parseInt $('*[data-user-hobbies-counter]').html()

              hobbyExist = $("*[data-user-hobbies] li[data-hobby-id='" + $(cb).val() + "']").length == 1

              if cb.is(':checked')
                if type == 'press'
                  if hobbyExist
                    $.ajax cb.attr('url'), { 
                      method: 'PATCH', data: { main: !label.hasClass('favorite') }, 
                      success: (data) ->
                        label.toggleClass 'favorite'
                        $("li[data-hobby-id=\"#{cb.val()}\"] input").prop 'checked', label.hasClass('favorite')
                      ,
                      error: (jqXHR, textStatus,errorThrown ) ->
                        if jqXHR.getResponseHeader('x-message')
                          $.y.flashMessage.create jqXHR.getResponseHeader('x-message'), jqXHR.getResponseHeader('x-message-type')
                        else
                          $.y.flashMessage.create I18n.t('js.hobbies.error'), 'alert'

                    }
                else
                  if hobbyExist
                    $.ajax cb.attr('url'), { method: 'DELETE', success: ->
                      $.y.flashMessage.create I18n.t('js.hobbies.delete'), 'alert'

                      $("li[data-hobby-id=\"#{cb.val()}\"]").remove()
                      $('*[data-user-hobbies-counter]').html counter - 1
                      cb.prop('checked', false).trigger 'change'
                      label.removeClass 'favorite'
                    }
              else
                unless hobbyExist
                  $.ajax cb.attr('url'), { 
                    method: 'PUT', 
                    data: { main: type == 'press' }, 
                    success: (data) ->
                      if data
                        $.y.flashMessage.create I18n.t('js.hobbies.add'), 'notice'

                        $('ul[data-user-hobbies]').append data
                        $('*[data-user-hobbies-counter]').html counter + 1
                        cb.prop('checked', true).trigger 'change'
                        label.addClass 'favorite' if type == 'press'
                    ,
                    error: (xhr, status, error) ->
                      $.y.flashMessage.create I18n.t('js.hobbies.max'), 'alert'
                      # $.y.flashMessage.create xhr.getResponseHeader('x-message'), xhr.getResponseHeader('x-message-type')
                  }

          # Prevent normal custom checkboxes behavior to manage click trought hammer
          $('label[data-hobby]').on 'click', (e) -> 
            e.preventDefault()
            # hobbyId = $(e.target).find('input').val()
            # toggleFavorite $("label[data-hobby='" + hobbyId + "']"), 'tap'

          # $(document).on 'tap press', 'label[data-hobby]', (e) ->
          for n in document.querySelectorAll('label[data-hobby]')
            # new Hammer($(this).get(0)).on 'tap press', (e) ->
            new Hammer(n).on 'tap press', (e) ->
              e.preventDefault()
              toggleFavorite $(e.target), e.type

          $('*[data-user-hobbies]').on 'click', 'a[data-hobby-remove]', (e) ->
            e.preventDefault()
            hobbyId = $(e.target).parents('*[data-hobby-id]').data 'hobby-id'
            toggleFavorite $("label[data-hobby='" + hobbyId + "']"), 'tap'

          $('*[data-user-hobbies]').on 'click', 'input', (e) ->
            e.preventDefault()
            hobbyId = $(e.target).parents('*[data-hobby-id]').data 'hobby-id'
            toggleFavorite $("label[data-hobby='" + hobbyId + "']"), 'press'

          $('a[data-user-hobbies-trigger]').click (e) ->
            e.preventDefault()
            $(e.target).parents('.hobbies-filter').toggleClass 'open'


        select_one_act: ->
          $('label[data-hobby] input').click (e) ->
            $('[data-select-hobby]').removeClass('btn-with-anim').addClass('btn-color-b btn-input').text $(e.target).parents('label').data('hobby')
            $('#event_hobby_id').val $(e.target).val()
            setTimeout ->
              $(e.target).trigger('y:close')
            , 200

        select_any_act: ->
          $('label[data-hobby] input').click (e) ->
            if $(e.target).is(':checked')
              model = $('[data-hobbies-selection]').data('hobbies-selection')
              $ """
                <button class="tag-toggle-btn delete" type="button">
                  <input type="hidden" name="#{model}[hobby_ids][]" value="#{$(e.target).val()}" id="#{model}_hobby_ids_#{$(e.target).val()}" />
                  <span class="label">#{$(e.target).parent().text()}</span> <span class="ico ico-trash"></span>
                </button>
                """
                .appendTo('[data-hobbies-selection]')
            else
              $("##{model}_hobby_ids_#{$(e.target).val()}").parent().remove()

      photos:

        index_act: ->
          nF = $('form[data-multipart]')
          nU = $('#uploading')

          nF.on('y:upload:progress', (e, loaded, total) ->
            nU.find('div[data-progress]').width("#{Math.round(loaded / total * 100)}%")
          ).on('ajax:beforeSend', -> nU.show()).on('ajax:complete', -> nU.hide())

          $('#file').change (e) -> nF.submit()

      events:

        index_act: ->
          $('input[type="checkbox"]').click (e) -> Turbolinks.visit($(e.target).data('url'))

        new_act: -> @edit_act()

        create_act: ->
          $('[data-create-back]').click (e) ->
            e.preventDefault()
            Rails.fire $(e.target).closest('form').prop('action', $(e.target).prop('href')).get(0), 'submit'
          @edit_act()

        update_act: -> @edit_act()

        edit_act: ->
          if $('body').hasClass('mobile') or $('body').hasClass('tablet')
            $('#event_start_at_date').change (e) -> $('#event_end_at_date').val($(e.target).val())
          else
            # console.log $('input[type=text].form-control-date')
            $('input[type=text].form-control-date')#.inputmask(I18n.t('js.inputmask.date'), { placeholder: '_' })
                                                   .datetimepicker({format: I18n.t('js.datepicker.date'), locale: I18n.locale, minDate: Date()})
            $('input[type=text].form-control-time')#.inputmask(I18n.t('js.inputmask.time'), { placeholder: '_' })
                                                   .datetimepicker({format: I18n.t('js.datepicker.time'), locale: I18n.locale, useCurrent: false})

            $('#event_start_at_date').on 'dp.change', (e) -> $('#event_end_at_date').data('DateTimePicker').minDate(e.date)
            $('#event_start_at_time').on 'dp.change', (e) -> $('#event_end_at_time').data('DateTimePicker').date(e.date.add 2, 'hours')

          ### Single friends ###
          single_friends_count_message = (selected_count = 0) -> 
            user_kind = store.state.config.currentUser.kind
            oposite_kind = if user_kind == 'male' then 'female' else 'male';

            $('[data-singles-friends-info]').html I18n.t("js.events.single_friends_count.#{user_kind}", {count: selected_count})
            $('[data-singles-friends-info]').toggleClass 'hidden', (selected_count == 0)

            $('[data-singles-friends-error]').toggleClass 'hidden', (selected_count > 0)

          single_friends_count_message $('[data-single-friends] .card--user.selected').length;

          $('[data-single-friends]').on 'click', '.card--user', (event) ->
            event.preventDefault();

            selected_count = $('[data-single-friends] .card--user.selected').length;
            selected_count += if $(event.currentTarget).hasClass('selected') then -1 else 1;

            if selected_count <= $('#event_max_attendees').val()
              $(event.currentTarget).toggleClass('selected');
              $(event.currentTarget).find(':checkbox').attr 'checked', $(event.currentTarget).hasClass('selected');

              single_friends_count_message selected_count;
            else
              Modal.alert I18n.t('js.events.alert_attendees.title'), I18n.t('js.events.alert_attendees.message');
              return false;

          $('[data-whos-in]').click (e) ->
            max_attendees = $(e.target).data('max-attendees');
            $('#event_max_attendees').val max_attendees;

            if $(e.target).data('whos-in') == 1
              $('[data-event-singles]').addClass 'hidden';
              $('[data-add-single]').attr 'disabled', true;
            else
              $('[data-event-singles]').removeClass 'hidden';
              $('[data-add-single]').attr 'disabled', false ;
              $('[data-event-singles-exists]').toggleClass 'hidden', $('[data-single-friends] .card--user').length > 0

            single_friends_count_message $('[data-single-friends] .card--user.selected').length;

          $('[data-single-friends="list"]').on  'y:domChanged', (e) -> 
            $('[data-event-singles-exists]').toggleClass 'hidden', $('[data-single-friends] .card--user').length > 0

          return unless input = $('[data-places-autocomplete]').get(0)
          $('[data-places-autocomplete]').keydown (e) -> e.preventDefault() if e.keyCode == 13
          autocomplete = new google.maps.places.Autocomplete input
          map          = new google.maps.Map $('#map-canvas').get(0), { center: {lat: 46.630579, lng: 2.526855}, zoom: 5, \
                                                                        disableDefaultUI: true, zoomControl: true, draggable: false, scrollwheel:  false }
          marker       = new google.maps.Marker { map: map }

          setPlace = (location) ->
            map.setCenter location
            map.setZoom(17)
            marker.setPosition location
            marker.setVisible true

          if $('#event_lat').val() && $('#event_lng').val()
            setPlace new google.maps.LatLng($('#event_lat').val(), $('#event_lng').val())

          google.maps.event.addListener autocomplete, 'place_changed', =>
            place = autocomplete.getPlace()
            return if !place.geometry

            if place.geometry.viewport
              map.fitBounds place.geometry.viewport
            else
              setPlace place.geometry.location

            $('#event_address_name').val(if 'point_of_interest' in place.types then place.name else '')
            $('#event_address').val place.formatted_address
            $('#event_lat').val place.geometry.location.lat()
            $('#event_lng').val place.geometry.location.lng()

            for ac in place.address_components
              $('#event_city_name').val(ac.long_name)    if 'locality' in ac.types
              $('#event_country_iso').val(ac.short_name) if 'country'  in ac.types

          unless $('#usb').length > 0
            $('#event_max_attendees').change (e) ->
              if $(e.target).val() > 10
                $(e.target).val(10)
                $.y.subscription.alert 'max_attendees', { noredirect: true }

        friends_act: ->
          calculateSelected = -> $('.friends-count-select span').text $('.row-friends-selector input:checked').length

          $('input[data-select-all]').click (e) ->
            $('.friends-selector input[type="checkbox"]:enabled').prop 'checked', $(e.target).prop('checked')
            $('.row-friends-selector').trigger 'y:domChanged'
            calculateSelected()

          $('.friends-selector input[type="checkbox"]').change -> calculateSelected()

      form_views:

        index_act: -> store.dispatch('counters/set', { field: 'formViews', value: 0 } )

      notifications:

        index_act: ->
          notifications = $('[data-notification], [data-attendee]').length - $('[data-notification]').length
          store.dispatch('counters/set', { field: 'notifications', value: if notifications > 0 then notifications else 0 } )
          $('[data-attendee]').on 'y:domRemove', (e) -> store.dispatch('counters/decrement', 'notifications')

          node      = $('#notifications-layout')
          height    = node.find('.col-fix').height()
          height    = node.find('.col-fluid').height() if node.find('.col-fluid').height() > height
          node.height(height)

          $.y.init 'tabs'
          
      subscriptions:

        index_act: ->
          $('[data-subscription]').click (e) ->
            console.log $(e.currentTarget)
            $.y.flashMessage.create I18n.t("js.subscription.#{$(e.currentTarget).data('subscription')}"), 'alert'
            e.preventDefault()

        show_act: ->
          $('#new_credit_card_form').on 'submit', -> $('.payment-form-loader').show()

          # unless $('body').hasClass('mobile') or $('body').hasClass('tablet')
          #   $('#credit_card_form_number').inputmask '9999 9999 9999 9999'
          #   $('#credit_card_form_cvv').inputmask '999', { placeholder: ' ' }

        create_act: -> @show_act()

      single_friends: 

        new_act: ->
          $('#single_friend_zip_code[type=text]').selectCityByZip()

          nF = $('form[data-multipart]')
          nU = $('#uploading')

          nF.on('y:upload:progress', (e, loaded, total) ->
            nU.find('div[data-progress]').width("#{Math.round(loaded / total * 100)}%")
          ).on('ajax:beforeSend', -> nU.show()).on('ajax:complete', -> nU.hide())

          $('[data-submit]').click (e) ->
            nF.submit()

        create_act: ->
          @new_act()

        edit_act: ->
          @new_act()

        update_act: ->
          @new_act()
}
