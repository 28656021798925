$.y.config.register()
$.y.counters.register()
$.y.init 'config'
$.y.init 'subscription'

$ ->
  $.y.init 'flashMessage', 'counters', 'customSelect', 'customCheckbox', 'customDropdown', 'dropdown', 'ujsExtended', 'multipart', 'emoji'

  $(document).on 'submit', 'form[method=get]', (e) ->
    e.preventDefault()
    Turbolinks.visit(@action + (if @action.indexOf('?') == -1 then '?' else '&') + $(@).serialize())

  # Field With Errors
  $(document).on 'click', '.field_with_errors', (e) ->
    $(e.currentTarget).removeClass 'field_with_errors'
    if $(e.currentTarget).siblings('.error-message').hide().length is 0
      $(e.target).closest('.form-group').find('.error-message').hide()

  # Reporting
  $(document).on 'click', '[data-report-trigger]', (e) ->
    e.preventDefault()
    $('#report').toggleClass('open')
    $('#report_form_resolution').val("#{$(window).width()}x#{$(window).height()} (#{window.screen.availWidth}x#{window.screen.availHeight})")

  # Remove cookie disclaimer
  $(document).on 'click', '#cookie-disclaimer button', (e) ->
    expires = new Date()
    expires.setTime expires.getTime() + (365 * 86400 * 1000)
    document.cookie = 'cookie-disclaimer=1;expires=' + expires.toUTCString()
    $('#cookie-disclaimer').remove()

  # Remove orientation disclaimer
  $(document).on 'click', '#disable-orientation button', (e) ->
    document.cookie = 'orientation-disclaimer=1;expires=0'
    $('#disable-orientation').remove()

  # Mobile menu
  $(document).on 'click', '[data-open-menu]', (e) -> $('html').toggleClass 'menu-is-open'
  $(document).on 'click', '[data-close-menu]', (e) -> $('html').removeClass 'menu-is-open'

  # Mobile tabs
  $.y.init 'tabs'
  
$(document).on 'turbolinks:load', ->
  $.y.flashMessage.reset().createFromDom()

  $.y.actionCallback $('body').data('controller'), $('body').data('action')

  # Pagination swipes
  if document.getElementById('template')
    (new Hammer(document.getElementById('template'), {cssProps:{userSelect:'auto'}})).on 'swipeleft swiperight', (e) ->
      dir = if e.type is 'swipeleft' then 'next' else 'prev'
      url = $(".nav-btn.#{dir}:not(.disabled)").first().parents('a').attr('href')
      Turbolinks.visit(url) if url

  # Trigger a dom change for custom components
  $(document).trigger 'y:domChanged'

  # Google Analytics
  if window.ga?
    ga 'set',  'location', location.href.split('#')[0]
    ga 'send', 'pageview', { title: document.title }

  # Facebook
  if window.fbq?
    fbq 'track', 'PageView'
