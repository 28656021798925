/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Lib's CSS
require("@rails/ujs").start()
require("turbolinks").start()

import I18n from 'i18n-js'
global.I18n = I18n

global.$ = jQuery // Test : To remove

import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex/dist/vuex.esm'
Vue.use(Vuex)
global.store = new Vuex.Store()

import Modal from 'commons/modal'
Modal.start()

require('inputmask/dist/jquery.inputmask')
require('jquery-bar-rating')
require('slick-carousel')
require('eonasdan-bootstrap-datetimepicker')

require('commons/main')
require('commons/controllers')
require('commons/admin_controllers')
require('commons/city')

require('commons/init')