#
# Yoos JS commons
#

import Modal from './modal'
import Rails from '@rails/ujs'
import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex/dist/vuex.esm'
import Hammer from 'hammerjs'
Vue.use Vuex

# Force a dom render to have proper transitions
$.fn.extend forceDomRendering: ->
  @get(0).clientHeight
  @

# JQuery findIncludeSelf
$.fn.extend findIncludeSelf: (selector) -> @find(selector).addBack(selector)

$.y ||= {}

$.extend $.y, {

  # Init objects
  init: (objects...) ->
    @[object].init() for object in objects

  # Execute a function by name
  executeByName: (name, context=@, args...) ->
    namespaces = name.split '.'
    fn         = namespaces.pop()

    try
      context = context[namespace] for namespace in namespaces
      throw(false) unless $.isFunction context[fn]
    catch e
      return false

    context[fn].apply context, args

  # Execute the action callback
  actionCallback: (controller, action) ->
    $.y.executeByName "controllers.#{controller}.#{action}_act" if controller && action

  # Execute the action callback from xhr object
  actionCallbackXhr: (xhr) -> $.y.actionCallback xhr.getResponseHeader('X-Controller'), xhr.getResponseHeader('X-Action')

  #---------------------#
  # Generic queue class #
  #---------------------#
  Queue: class
    constructor: (@_data = []) ->
    isEmpty:       => @_data.length == 0
    enqueue: (obj) => @_data.push obj
    shift:         => @_data.shift()
    clear:         => @_data = []
    size:          => @_data.length

  #---------#
  # Config  #
  #---------#
  config:
    register: ->
      store.registerModule 'config', {
        state: {
          currentUser: null,
          location: null,
          device: 0
        },
        mutations: {
          loadCurrentUser: (state, currentUser) -> state.currentUser = currentUser
          loadLocation: (state, location) -> state.location = location
          loadDevice: (state, device) -> state.device = device
        },
        actions: {
          loadCurrentUser: (context, currentUser) -> context.commit('loadCurrentUser', currentUser)
          loadLocation: (context, location) -> context.commit('loadLocation', location)
          loadDevice: (context, device) -> context.commit('loadDevice', device)
        },
        namespaced: true
      }

    init: ->
      # Load config
      $.getJSON '/app_config.json', (data) =>
        store.dispatch 'config/loadCurrentUser', data.config.current_user
        store.dispatch 'config/loadLocation', data.config.geolocation
        store.dispatch 'config/loadDevice', data.config.device

        store.dispatch 'counters/set', { field: 'chatMessages', value: data.counters.chat }
        store.dispatch 'counters/set', { field: 'formViews', value: data.counters.form_view }
        store.dispatch 'counters/set', { field: 'notifications', value: data.counters.notification }
        
        $(document).trigger 'y:configLoaded', data

  #---------------#
  # Flash message #
  #---------------#
  flashMessage:
    init: ->
      @reset()
      $(document).on 'ajax:success', (e) =>
        xhr  = e.detail[2]
        data = xhr.responseText
        @_createFromXhr(xhr) unless data && typeof data is 'string' && data.match(/^Turbolinks/)
      $(document).on 'ajax:error', (e) => @_createFromXhr(e.detail[2])

      $(document).on 'turbolinks:before-fetch-response', (e) => @_createFromResponse(e.detail.fetchResponse)

    reset: ->
      @_queue      = new $.y.Queue
      @_displaying = false
      @

    create: (message, type='notice') ->
      if @_displaying
        @_queue.enqueue arguments
      else
        @_node().toggleClass 'alert', type is 'alert'
        @_message().text message
        @_show()

    createFromDom: ->
      @_show() if !@_displaying && @_message().text()

    _createFromXhr: (xhr) ->
      if xhr.getResponseHeader('X-Message')
        @create decodeURIComponent(escape(xhr.getResponseHeader('X-Message'))), xhr.getResponseHeader('X-Message-Type')

    _createFromResponse: (fr) ->
      if fr.header('X-Message')
        setTimeout =>
          @create decodeURIComponent(escape(fr.header('X-Message'))), fr.header('X-Message-Type')
        , 500

    _node: -> $ '[data-flash-message]'

    _message: -> @_node().find '.message'

    _show: ->
      @_displaying = true
      @_node().forceDomRendering().addClass('show')
      setTimeout =>
        @_node().removeClass 'show'
        @_node().one 'transitionend', => @_onHide()
      , 2500

    _onHide: ->
      @_node().removeClass 'alert'
      @_message().text ''
      @_displaying = false
      @create.apply @, @_queue.shift() unless @_queue.isEmpty()

  #----------#
  # Counters #
  #----------#
  # TODO: Check y:counter.change event
  # counter:
  #   increment: (type, inc=1) -> @setValue(type, @getValue(type) + inc)
  #   decrement: (type, dec=1) -> @setValue(type, @getValue(type) - dec)
  #   reset: (type) -> @setValue(type, 0)

  #   getValue: (type) -> parseInt(@_pill(type).attr('data-value'))
  #   setValue: (type, value) ->
  #     value = Math.max(0, value)
  #     @_pill(type).attr('data-value', value)
  #     $(document).trigger 'y:counter.change', [type, value]

  #   _node: (type) -> $ "[data-counter='#{type}']"
  #   _pill: (type) -> @_node(type).find('span')

  #--------------#
  # Counters bar #
  #--------------#
  counters:
    register: ->
      store.registerModule 'counters', {
        state: {
          chatMessages: 0
          formViews: 0
          notifications: 0
        },
        mutations: {
          set: (state, payload) -> state[payload.field] = payload.value
          increment: (state, field) -> state[field] += 1
          decrement: (state, field) -> state[field] -= 1 if state[field] > 0
        },
        actions: {
          set: (context, payload) -> context.commit('set', payload)
          increment: (context, field) -> context.commit('increment', field)
          decrement: (context, field) -> context.commit('decrement', field)
        },
        namespaced: true
      }

    init: ->
      @vues = $('[data-counters-bar]').map (i, n) =>
        new Vue {
          name: "CountersBar#{i + 1}",
          el: n,
          store,
          computed: Vuex.mapState('counters', ['chatMessages', 'formViews', 'notifications'])
        }

      $(document).on 'y:configLoaded', (e, data) => @_loadStats(data.counters)

    _loadStats: (counters) ->
      store.dispatch 'counters/set', { field: 'chatMessages', value: counters.chat }
      store.dispatch 'counters/set', { field: 'formViews', value: counters.form_view }
      store.dispatch 'counters/set', { field: 'notifications', value: counters.notification }


  #---------------#
  # Custom select #
  #---------------#
  customSelect:
    init: ->
      $(document).on 'change', @_selector, (e) => @_load($(e.target))

      $(document).on 'y:domChanged', (e) =>
        $(e.target).findIncludeSelf(@_selector).each (i, elmt) => @_load($(elmt))

    _selector: '.custom-select select'

    _load: (target) ->
      target.parents('.custom-select').children('.label').text(target.children('option:selected').text())

  #-----------------#
  # Custom checkbox #
  #-----------------#
  customCheckbox:
    init: ->
      $(document).on 'change', @_selector, (e) =>
        if $(e.target).prop('type') is 'radio'
          $("[name=\"#{$(e.target).prop('name')}\"]").each (i, elmt) => @_load($(elmt))
        else
          @_load($(e.target))
        $(e.target).closest('form').submit() if $(e.target).data('submit-on-change')

      $(document).on 'y:domChanged', (e) =>
        $(e.target).findIncludeSelf(@_selector).each (i, elmt) => @_load($(elmt))

    _selector: '.tag-toggle-btn input, .friends-selector input, .form-checkbox input:not([data-remote])'

    _load: (target) ->
      target.closest('label').toggleClass('checked', target.prop('checked'))

  #-----------------#
  # Custom dropdown #
  #-----------------#
  customDropdown:
    init: ->
      $(document).on 'click', @_selector, (e) => @_show($(e.currentTarget))

    _selector: '.dropdown:not(.open)'

    _show: (target) ->
      target.addClass 'open'
      $('body').on 'click.y.dropdown', (e) =>
        e.preventDefault()
        @_hide(target)

    _hide: (target) ->
      target.removeClass 'open'
      $('body').off 'click.y.dropdown'

  #----------#
  # Dropdown #
  #----------#
  dropdown:
    init: ->
      $(document).on 'click', '[data-dropdown-trigger]', (e) => @_toggle($(e.currentTarget))

    _toggle: (target) ->
      elmt = if target.attr('data-dropdown-trigger') isnt ''
        $(target.attr('data-dropdown-trigger'))
      else
        target.closest('[data-dropdown]')

      isOpen = elmt.hasClass 'is-open'
      if isOpen
        elmt.removeClass 'is-open'
      else
        @_closeAll()
        elmt.addClass 'is-open'
        $('body').on 'click.me.dropdown', (e) =>
          return if $(e.target).is('[data-dropdown-panel]') || $(e.target).closest('[data-dropdown-trigger]').length > 0
          @_closeAll()

    _closeAll: (target) ->
      $('[data-dropdown],[data-dropdown-panel]').removeClass 'is-open'
      $('body').off 'click.me.dropdown'

  #-----------------#
  # Tabs management #
  #-----------------#
  tabs:
    _index:  null

    init: ->
      @_tabs = $('[data-tabs]')
      return unless @_tabs.length > 0

      @_triggers  = @_tabs.find('a')
      @_panels    = $('[data-tabs-panel]')
      @_offset    = Math.round(@_tabs.offset().top - 54)
      @_triggers.on 'click', (e) =>
        e.preventDefault()
        @show $(e.currentTarget).index()
      $(window).on 'scroll', (e) =>
        ofb = $(e.target).scrollTop() >= @_offset
        $('html').toggleClass 'tabs-sticky', ofb
        @_tabs.toggleClass 'sticky', ofb

    show: (index) ->
      elmts.removeClass('active').eq(index).addClass 'active' for elmts in [@_triggers, @_panels]
      $('html, body').scrollTop(if @_tabs.hasClass 'sticky' then @_offset else 0)

  #----------------#
  # Json Utilities #
  #----------------#
  jsonUtils:
    dateTimeFormat: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?[Z\+]{1}([\d\:]{5})?$/

    reviver: (key, value) ->
      if typeof value is 'string' && jsonUtils.dateTimeFormat.test(value)
        return new Date(value)
      else
        return value

  # Extend UJS : update DOM on ajax:success with the following actions : update, prepend, append, replace, remove
  # A selector should be provided in the data attribute
  # A replace action without selector will replace the whole template
  ujsExtended:
    init: ->
      $(document).on 'ajax:success', '[data-update],[data-prepend],[data-append],[data-replace],[data-remove]', (e) ->
        [data, status, xhr] = e.detail
        unless xhr.responseText and typeof xhr.responseText is 'string' and xhr.responseText.match(/^Turbo/)
          if elmt = $(e.target).data 'remove'
            $(elmt).trigger('y:domRemove').remove()
          else
            if elmt = $(e.target).data 'update' then $(elmt).html xhr.responseText
            else if elmt = $(e.target).data 'prepend' then $(elmt).prepend xhr.responseText
            else if elmt = $(e.target).data 'append'  then $(elmt).append xhr.responseText
            else if (elmt = $(e.target).data 'replace')?
              if elmt
                elmt = $(xhr.responseText).replaceAll(elmt)
              else
                elmt = $(xhr.responseText).replaceAll('#template')
                window.scrollTo 0,0
                $.y.actionCallbackXhr xhr

            $(elmt).trigger 'y:domChanged' if elmt

  # Ajax file upload using FormData
  multipart:
    init: ->
      $(document).on 'submit', 'form[data-multipart]', (e) ->
        f = $(e.target)
        inputFile = null
        $.ajax({
          url: f.attr('action'), data: new FormData(f.get(0)), cache: false, contentType : false, processData : false, type: 'POST',
          beforeSend: ->
            $(document).on 'click.lock', (e) ->
              if $(f).find(':file').get(0).files.length > 0
                $.y.flashMessage.create "Upload en cours, veuillez patienter...", 'alert'

              e.preventDefault()
            if Rails.fire(f[0], 'ajax:beforeSend')
              f.find('button').prop 'disabled', true
            else
              false
          ,
          complete: (data, status, xhr) ->
            $(document).off 'click.lock'
            f.find('button').prop 'disabled', false
            f.trigger jQuery.Event('ajax:complete', { detail: [data, status, xhr] })
          ,
          success: (data, status, xhr) ->
            f.trigger jQuery.Event('ajax:success', { detail: [data, status, xhr] })
          ,
          error: (xhr, status, error) ->
            f.trigger jQuery.Event('ajax:error', { detail: [status, error, xhr] })
          xhr: ->
            xhr = $.ajaxSettings.xhr()
            xhr.upload.addEventListener 'progress', (e) ->
              f.trigger 'y:upload:progress', [ e.loaded, e.total ]
            , false
            xhr
        })
        false

  # Emoji
  emoji:
    library:
      '😊': ":[\\\)D]{1}"
      '😉': ";[\\\)D]{1}"
      '😂': ":'[\\\)D]{1}"
      '😝': ":p"
      '😍': "\\\<3"
      '😲': ":o"
      '😖': ":\\\|"
      '😞': ":\\\("
      '😢': ":'\\\("
      '😸': "\\\^{2}"

    init: (listen=false) ->
      @library[k] = new RegExp "(^|\\s)(#{e})($|\\s)", 'gi' for k, e of @library
      if listen
        $(document).on 'keyup', 'textarea[data-emoticonize]', (e) => @_emoticonizeInput(e.currentTarget)

    emoticonize: (text) ->
      text = text.replace(e, "$1#{k}$3") for k, e of @library
      text

    _emoticonizeInput: (node) ->
      text       = node.value
      curPos     = node.selectionStart
      curLength  = text.length
      text       = @emoticonize(text)
      node.value = text
      newPos     = curPos + text.length - curLength
      node.setSelectionRange(newPos, newPos)

  # Subscription
  subscription:
    init: ->
      unless $('#usb').length > 0
        $(document).on 'y:domChanged', (e) =>
          $(e.target).findIncludeSelf('[data-subscriber]').each (i, elmt) =>
            node = $(elmt)
            if node.is('[data-remote]') or node.is('[data-multipart]')
              node.on 'ajax:beforeSend', =>
                @alert node.data('subscriber')
                return false
            else if node.is('a,input')
              node.click (e) =>
                e.preventDefault()
                @alert node.data('subscriber'), { noredirect: node.is('input') }

    alert: (type, options={}) ->
      type = 'default' unless type?.length
      Modal.alert I18n.t('js.subscription.alert.title'), I18n.t("js.subscription.alert.#{type}"), ->
        options['callback']() if options['callback']
        Turbolinks.visit('/account/subscriptions') unless options['noredirect']

  # # Facebook Pixel
  # facebookPixel:
  #   init: ->
  #     node = $('#fb-root')
  #     if node.length > 0
  #       window.fbAsyncInit = -> FB.init({appId: node.data('app-id'), status: true, cookie: true, xfbml: true, version: 'v2.8'})
  #       $.getScript '//connect.facebook.net/en_US/all.js#xfbml=1'

}

# For compatibility sake
actionCallbackXhr = $.y.actionCallbackXhr
flashMessage = $.y.flashMessage
counter = $.y.counter
counters = $.y.counters
emoji = $.y.emoji
subscription = $.y.subscription
jsonUtils = $.y.jsonUtils

export { actionCallbackXhr, flashMessage, counter, emoji, subscription, jsonUtils }
